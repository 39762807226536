 <template>
    <section class="pt-lg-8 pt-4 place-holder-white">
        <div class="container full-height p-lg-4 pt-8">

            <login-heading heading="Login to Bank e Auctions India.com" class="mb-5">
                <template #info>
                    <p class="d-inline mr-1">Don't have an account ?</p>
                    <span class="text-secondary"><router-link class="text-decoration-none"
                                                              to="/register/">Register Now</router-link></span>
                </template>
            </login-heading>

            <s-form ref="loginForm" class="row" @submit="validateCredentials">
                <div class="col-12 col-lg-4">
                    <validated-input label="Email" class="c-input" v-model="model.username"
                                     :disabled="loading" name="Username" type="email" :rules="{required:true}"/>

                    <validated-input type="password" label="Password" class="c-input" v-model="model.password"
                                     :disabled="loading" :rules="{required:true}"/>

                    <p v-if="invalidCredential" class="text-danger">{{ message }}</p>

                    <div class="fl-x fl-j-e">
                        <p class="text-2">
                            <router-link class="link-underline" to="/forgot-password/">Forgot Password</router-link>
                        </p>
                    </div>

                    <btn :disabled="loading" block text="LOGIN" :loading="loading"
                         loading-text="Validating Credentials..."
                         class="mt-4 py-3 border-r--2 auth-button"></btn>
                </div>
            </s-form>

        </div>
    </section>
</template>

<script>
import urls from '@/data/urls';
import LoginHeading from '@components/login/LoginHeading';
import axios from 'secure-axios';

export default {
    name: 'Login',

    components: { LoginHeading },

    data () {
        return {
            loginUrl: urls.auth.login,
            loading: false,
            invalidCredential: false,
            model: {
                username: '',
                password: ''
            },
            mount: true,

            message: ''
        };
    },

    beforeMount () {
        this.loadData();
    },

    methods: {
        async loadData () {
            const response = await axios.get(urls.auth.status);
            if (response.data['public-key']) {
            } else {
            }
        },

        async validateCredentials () {
            const that = this;
            this.loading = true;
            this.invalidCredential = false;
            const response = await axios.form(
                urls.auth.login, this.model, ['username', 'password']
            ).catch(function (exception) {
                that.serverError();
            });

            const json = response.data;
            if (json.error === true) {
                this.invalidCredentials(json);
            } else if (json.error === false) {
                this.validCredentials(json);
            } else {
                this.invalidCredentials(json);
            }
            this.loading = false;
        },

        validCredentials (data) {
            if (this.$route.hash) {
                this.$router.push('/verify-mobile/' + data.mobile + '/' + this.$route.hash);
            } else {
                this.$router.push('/verify-mobile/' + data.mobile + '/');
            }
        },

        invalidCredentials (json) {
            this.invalidCredential = true;
            this.message = json.errors.__all__ || 'Invalid Credentials';
        },

        serverError () {
            this.invalidCredential = true;
            this.message = 'Server error, Please try again later.';
        }
    }
};
</script>
<style>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.bg-login {
    /*background-image: url("../../assets/img/bg/bg-login-1.jpg");*/
    /*background-image: url("../../assets/img/bg/login-bg-3.jpg");*/
    background-size: cover;
    background-position: center;
    position: relative;
}

.bg-login:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    /*background-color: #00000042;*/
    /*for bg 2*/
    /*background-color: rgba(0,153,255,0.15);*/
    /*for bg-3*/
    background-color: rgba(0, 153, 255, 0.68);
    /*for bg 4*/
    /*background-color: rgba(0, 153, 255, 0.15);*/
    background-size: cover;
    /*background-position: left;*/
    background-position: 11% 18%;
    z-index: 0;
}

.login-card {
    width: 380px;
    /*background: #033556;*/
    /*background: var(--color-primary-700);*/
    /*background: var(--color-primary-600);*/
    /*background: rgb(4, 213, 255);*/
    /*background: linear-gradient(100deg, rgba(4, 213, 255, 1) 12%, rgba(0, 153, 255, 1) 88%);*/
    /*background-color: var(--bg-8);*/
    padding: 10px;
}

.inner-card {
    /*background: #d3deebfa;*/
    padding: 15px;
    border-radius: 5px;
}
</style>
